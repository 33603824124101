import { COLORS } from '../../data/colors';

export const MAX_METERS_PER_SECOND = 36;

export const DEFAULT_LABEL_PROPS = {
  fill: COLORS.LABEL,
  fontSize: 14,
  fontWeight: 100,
  fontFamily: 'sans-serif',
  textAnchor: 'middle',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tickLabelProps: any = () => ({ ...DEFAULT_LABEL_PROPS, fontSize: 11 });

export const DEFAULT_AXIS_LABEL_PROPS = {
  ...DEFAULT_LABEL_PROPS,
  strokeWidth: 0,
  stroke: '#fff',
  paintOrder: 'stroke',
};

export const DEFAULT_CHART_MARGINS = {
  top: 60,
  right: 20,
  bottom: 50,
  left: 20,
};
