import { createTheme } from '@mui/material/styles';

import { FONT_FAMILY } from './constants';

export const theme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
      },
    },
  },
});
