import { createGlobalStyle } from 'styled-components';

import { COLORS } from './data/colors';
import { FONT_FAMILY } from './layout/constants';

export const GlobalStyle = createGlobalStyle`
  html {
   margin: 0;
   padding: 0;
   background-color: ${COLORS.BACKGROUND};
   font-family: ${FONT_FAMILY};
  }
  
  body {
    margin: 0;
    padding: 0;
  }
 `;
