import { METERS_PER_SECOND_PER_KNOT } from './knots';

interface BeaufortItem {
  force: number;
  description: string;
  from: number;
  to: number;
}

export const BEAUFORT_ITEMS: BeaufortItem[] = [
  {
    force: 0,
    description: 'Calm',
    from: 0,
    to: 0.3,
  },
  {
    force: 1,
    description: 'Light Air',
    from: 0.3,
    to: 1.5,
  },
  {
    force: 2,
    description: 'Light Breeze',
    from: 1.6,
    to: 3.3,
  },
  {
    force: 3,
    description: 'Gentle Breeze',
    from: 3.4,
    to: 5.4,
  },
  {
    force: 4,
    description: 'Moderate Breeze',
    from: 5.5,
    to: 7.9,
  },
  {
    force: 5,
    description: 'Fresh Breeze',
    from: 8,
    to: 10.7,
  },
  {
    force: 6,
    description: 'Strong Breeze',
    from: 10.8,
    to: 13.8,
  },
  {
    force: 7,
    description: 'Near Gale',
    from: 13.9,
    to: 17.1,
  },
  {
    force: 8,
    description: 'Gale',
    from: 17.2,
    to: 20.7,
  },
  {
    force: 9,
    description: 'Strong Gale',
    from: 20.8,
    to: 24.4,
  },
  {
    force: 10,
    description: 'Storm',
    from: 24.5,
    to: 28.4,
  },
  {
    force: 11,
    description: 'Violent Storm',
    from: 28.5,
    to: 32.5,
  },
  {
    force: 12,
    description: 'Hurricane',
    from: 32.6,
    to: 36,
  },
];

export const getBeaufortByMetersPerSecond = (metersPerSecond: number): number =>
  BEAUFORT_ITEMS.filter(i => metersPerSecond >= i.from && metersPerSecond < i.to + 0.1)[0]?.force ?? 0;

export const getBeaufortByKnots = (knots: number): number =>
  BEAUFORT_ITEMS.filter(i => knots * METERS_PER_SECOND_PER_KNOT >= i.from && knots * METERS_PER_SECOND_PER_KNOT < i.to + 0.1)[0]?.force ??
  0;

export const getBeaufortDescription = (force: number): string => BEAUFORT_ITEMS.find(i => i.force === force)?.description ?? '';
