import { enumObjectToSelectOptions, enumToObject } from './utils';

export enum RiderWeightClass {
  W50_60 = '50-60',
  W60_70 = '60-70',
  W70_80 = '70-80',
  W80_90 = '80-90',
  W90_UP = '90+',
}

export const RIDER_WEIGHT_CLASS = enumToObject(RiderWeightClass);

export const RIDER_WEIGHT_CLASS_OPTIONS = enumObjectToSelectOptions(RIDER_WEIGHT_CLASS).map(({ value, title }) => ({
  value,
  title: `${title} kg.`,
}));

export enum RiderSkillLevel {
  BEGINNER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
}

export const RIDER_SKILL_LEVEL = enumToObject(RiderSkillLevel);

export const RIDER_SKILL_LEVEL_OPTIONS = enumObjectToSelectOptions(enumToObject(RiderSkillLevel));

export interface ByWeightClass<T> {
  [RiderWeightClass.W50_60]: T;
  [RiderWeightClass.W60_70]: T;
  [RiderWeightClass.W70_80]: T;
  [RiderWeightClass.W80_90]: T;
  [RiderWeightClass.W90_UP]: T;
}
