/* https://windy.app/blog/how-to-choose-the-right-windsurf-sail-size.html#:~:text=For%20amateur%20riders%2C%20boards%20in,)%20%E2%80%94%203.5%2D4%20m%C2%B2. */

import { ByWeightClass, RiderWeightClass } from './rider';

export interface SailSizeItem {
  from: number;
  to: number;
  riderWeightClass: ByWeightClass<string>;
}

export const SAILSIZE_ITEMS: SailSizeItem[] = [
  {
    from: 3,
    to: 4,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '7-9',
      [RiderWeightClass.W60_70]: '8-10',
      [RiderWeightClass.W70_80]: '9-11',
      [RiderWeightClass.W80_90]: '10-12',
      [RiderWeightClass.W90_UP]: '12+',
    },
  },
  {
    from: 4,
    to: 5,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '6-8',
      [RiderWeightClass.W60_70]: '7-9',
      [RiderWeightClass.W70_80]: '7-10',
      [RiderWeightClass.W80_90]: '9-11',
      [RiderWeightClass.W90_UP]: '11+',
    },
  },
  {
    from: 5,
    to: 7,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '5-6.5',
      [RiderWeightClass.W60_70]: '6-7.5',
      [RiderWeightClass.W70_80]: '6.5-8',
      [RiderWeightClass.W80_90]: '7.5-9.5',
      [RiderWeightClass.W90_UP]: '9+',
    },
  },
  {
    from: 7,
    to: 9,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '4.5-5.5',
      [RiderWeightClass.W60_70]: '5-6.2',
      [RiderWeightClass.W70_80]: '5.5-7',
      [RiderWeightClass.W80_90]: '6.5-8',
      [RiderWeightClass.W90_UP]: '7.5+',
    },
  },
  {
    from: 9,
    to: 11,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '3.3-4.3',
      [RiderWeightClass.W60_70]: '4-5',
      [RiderWeightClass.W70_80]: '4.5-5.5',
      [RiderWeightClass.W80_90]: '5-6',
      [RiderWeightClass.W90_UP]: '5.5+',
    },
  },
  {
    from: 11,
    to: 13,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '2.8-3.7',
      [RiderWeightClass.W60_70]: '3.3-4',
      [RiderWeightClass.W70_80]: '3.8-5',
      [RiderWeightClass.W80_90]: '4.5-5.5',
      [RiderWeightClass.W90_UP]: '4.7+',
    },
  },
  {
    from: 13,
    to: 16,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '2.5-3.5',
      [RiderWeightClass.W60_70]: '3.1-3.7',
      [RiderWeightClass.W70_80]: '3.5-4.2',
      [RiderWeightClass.W80_90]: '4.2-4.7',
      [RiderWeightClass.W90_UP]: '4+',
    },
  },
  {
    from: 16,
    to: 19,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '2.3-3',
      [RiderWeightClass.W60_70]: '2.5-3.5',
      [RiderWeightClass.W70_80]: '3-4',
      [RiderWeightClass.W80_90]: '3.5-4.3',
      [RiderWeightClass.W90_UP]: '3.5+',
    },
  },
  {
    from: 19,
    to: 36,
    riderWeightClass: {
      [RiderWeightClass.W50_60]: '< 2.7',
      [RiderWeightClass.W60_70]: '< 3',
      [RiderWeightClass.W70_80]: '< 3.5',
      [RiderWeightClass.W80_90]: '< 4',
      [RiderWeightClass.W90_UP]: '< 4.5',
    },
  },
];

export const getSailSizeByMetersPerSecondAndRiderWeightClass = (metersPerSecond: number, riderWeightClass: RiderWeightClass): string =>
  SAILSIZE_ITEMS.filter(i => metersPerSecond >= i.from && metersPerSecond < i.to)[0]?.riderWeightClass[riderWeightClass];
