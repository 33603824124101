import React, { useCallback } from 'react';
import { Line, Bar } from '@visx/shape';
import { withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { Orientation, Axis } from '@visx/axis';
import { scaleLinear } from '@visx/scale';

import { BEAUFORT_ITEMS } from '../../data/beaufort';
import { COLORS } from '../../data/colors';
import { KNOT_VALUES } from '../../data/knots';

import { DEFAULT_CHART_MARGINS, DEFAULT_AXIS_LABEL_PROPS, MAX_METERS_PER_SECOND, tickLabelProps } from './constants';

export interface TooltipData {
  metersPerSecond: number;
}

interface Props {
  onTooltipChange: (value: number | null) => void;
  width?: number;
  height?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}

export const BandChart = withTooltip<Props, TooltipData>(
  ({
    width: outerWidth = 800,
    height: outerHeight = 200,
    onTooltipChange,
    margin = DEFAULT_CHART_MARGINS,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft = 0,
  }: Props & WithTooltipProvidedProps<TooltipData>) => {
    if (outerWidth < 10) return null;
    const width = outerWidth - margin.left - margin.right;
    const height = outerHeight - margin.top - margin.bottom;

    const onMouseLeave = useCallback(() => {
      hideTooltip();
      onTooltipChange(null);
    }, [hideTooltip, onTooltipChange]);

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const metersPerSecond = ((x - margin.left) / width) * MAX_METERS_PER_SECOND;
        if (metersPerSecond >= 0 && metersPerSecond <= MAX_METERS_PER_SECOND) {
          showTooltip({
            tooltipData: { metersPerSecond },
            tooltipLeft: x,
          });
          onTooltipChange(metersPerSecond);
        } else {
          showTooltip({});
          onMouseLeave();
        }
      },
      [showTooltip, onTooltipChange, onMouseLeave]
    );

    return (
      <svg width={outerWidth} height={outerHeight}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {BEAUFORT_ITEMS.map(({ from, to, force }, i) => {
            const x = (from * width) / MAX_METERS_PER_SECOND;
            const w = ((to + 0.1 - from) * width) / MAX_METERS_PER_SECOND;
            return (
              <g key={force}>
                <rect x={x} y={0} width={w} height={height} fill={COLORS.PALETTE[(i + 1) * 2]} />
                {force > 0 && (
                  <text x={x + w / 2} y={height / 2 + 3} fill="#fff" fontSize="12" fontFamily="sans-serif" textAnchor="middle">
                    {force}
                  </text>
                )}
              </g>
            );
          })}
          <rect x={width} y={0} width={5} height={height} fill={COLORS.BACKGROUND} />
        </g>

        <g transform={`translate(${margin.left},${margin.top})`}>
          <Bar
            x={0}
            width={width}
            height={height}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={onMouseLeave}
          />
          <Axis
            orientation={Orientation.top}
            top={0}
            scale={scaleLinear({ domain: [0, KNOT_VALUES.length], range: [0, width] })}
            numTicks={KNOT_VALUES.length / 5 + 1}
            stroke={COLORS.AXIS}
            strokeWidth={1}
            tickStroke={COLORS.LINE}
            label="knots"
            /* @ts-ignore */
            labelProps={{ ...DEFAULT_AXIS_LABEL_PROPS, y: -30 }}
            /* @ts-ignore */
            tickLabelProps={tickLabelProps}
          />
          <Axis
            orientation={Orientation.bottom}
            top={height}
            scale={scaleLinear({ domain: [0, MAX_METERS_PER_SECOND], range: [0, width] })}
            numTicks={MAX_METERS_PER_SECOND / 2 + 1}
            stroke={COLORS.AXIS}
            strokeWidth={1}
            tickStroke={COLORS.LINE}
            label="m/s"
            /* @ts-ignore */
            labelProps={{ ...DEFAULT_AXIS_LABEL_PROPS, y: 44 }}
            /* @ts-ignore */
            tickLabelProps={tickLabelProps}
          />
        </g>
        {tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: margin.top }}
            to={{ x: tooltipLeft, y: height + margin.top }}
            stroke={COLORS.CROSSHAIR}
            strokeWidth={1}
            pointerEvents="none"
            // strokeDasharray="5,2"
          />
        )}
      </svg>
    );
  }
);
