import { ByWeightClass, RiderSkillLevel, RiderWeightClass } from './rider';

export interface BoardSizes {
  [RiderSkillLevel.BEGINNER]: ByWeightClass<string>;
  [RiderSkillLevel.INTERMEDIATE]: ByWeightClass<string>;
}

export const BOARDSIZES = {
  [RiderSkillLevel.BEGINNER]: {
    [RiderWeightClass.W50_60]: '145-160',
    [RiderWeightClass.W60_70]: '160-175',
    [RiderWeightClass.W70_80]: '175-190',
    [RiderWeightClass.W80_90]: '190-205',
    [RiderWeightClass.W90_UP]: '205+',
  },
  [RiderSkillLevel.INTERMEDIATE]: {
    [RiderWeightClass.W50_60]: '95-108',
    [RiderWeightClass.W60_70]: '108-121',
    [RiderWeightClass.W70_80]: '121-134',
    [RiderWeightClass.W80_90]: '134-147',
    [RiderWeightClass.W90_UP]: '147+',
  },
};

export const getBoardSizeByRiderWeightClass = (riderSkillLevel: RiderSkillLevel, riderWeightClass: RiderWeightClass): string =>
  BOARDSIZES[riderSkillLevel][riderWeightClass];
