import { Option } from '../components/Select';

export const round = (value: number, decimals = 1): number => Math.round(value * 10 * decimals) / (10 * decimals);

interface EnumObject {
  key: string;
  value: string;
}

export const enumToObject = (
  enumObject: unknown
): {
  key: string;
  value: string;
}[] => Object.entries(enumObject as EnumObject).map(([key, value]) => ({ key, value }));

export const enumObjectToSelectOptions = (enumObject: EnumObject[]): Option[] =>
  enumObject.map(({ key, value }) => ({ value: key, title: value }));
