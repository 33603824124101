import React, { useState } from 'react';
import { ThemeProvider, Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { useWindowSize, useCookie } from 'react-use';

import { GlobalStyle } from './GlobalStyle';
import { BandChart } from './charts/band/BandChart';
import { BeauchartLogo } from './layout/BeauchartLogo';
import { InfoBlock } from './layout/InfoBlock';
import { BREAKPOINT } from './layout/constants';
import { theme } from './layout/theme';
import { MedianiqueLogo } from './layout/MedianiqueLogo';
import { COLORS } from './data/colors';
import { RIDER_SKILL_LEVEL_OPTIONS, RIDER_WEIGHT_CLASS_OPTIONS, RiderWeightClass, RiderSkillLevel } from './data/rider';
import { Select } from './components/Select';

export const App: React.FC = () => {
  const { width } = useWindowSize();
  const [windSurferWeightClass, setWindSurferWeightClass] = useCookie('windSurferWeightClass');
  const [windSurferSkillLevel, setWindSurferSkillLevel] = useCookie('windSurferSkillLevel');
  const [metersPerSecond, setMetersPerSecond] = useState<number | null>(null);

  const height = width > 600 ? 200 : 180;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <FlexItem>
          <LogoWrapper>
            <BeauchartLogo />
          </LogoWrapper>
          <InfoBlock
            metersPerSecond={metersPerSecond}
            // @ts-ignore
            windSurferWeightClass={RiderWeightClass[windSurferWeightClass]}
            // @ts-ignore
            windSurferSkillLevel={RiderSkillLevel[windSurferSkillLevel]}
          />
        </FlexItem>
        <FlexItem>
          <BandChart key={`band-chart-${width}`} onTooltipChange={setMetersPerSecond} width={width} height={height} />
        </FlexItem>
        <FlexItem>
          <Box sx={{ mt: 3 }}>
            {width <= 500 && <Typography sx={{ mb: 2 }}>Windsurfer</Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2, alignItems: 'center', justifyContent: 'center' }}>
              {width > 500 && <Typography>Windsurfer</Typography>}
              <Select
                label="Weight"
                value={windSurferWeightClass ?? ''}
                options={RIDER_WEIGHT_CLASS_OPTIONS}
                onChange={e => setWindSurferWeightClass(e.target.value)}
                sx={{ width: '8rem', textAlign: 'left' }}
              />

              <Select
                label="Skill Level"
                value={windSurferSkillLevel ?? ''}
                options={RIDER_SKILL_LEVEL_OPTIONS}
                onChange={e => setWindSurferSkillLevel(e.target.value)}
                sx={{ width: '9rem', textAlign: 'left' }}
              />
            </Box>
          </Box>
        </FlexItem>
        <FlexItemBottom>
          <FooterWrapper>
            <a href="https://medianique.nl" title="Medianique">
              <MedianiqueLogo />
            </a>
          </FooterWrapper>
        </FlexItemBottom>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  flex: 1;
  text-align: center;
`;

const FlexItemBottom = styled(FlexItem)`
  display: flex;
  align-items: flex-end;
  padding: 40px 0 20px;
`;

const LogoWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin: 40px auto 0;
  width: 90vw;
  @media only screen and (min-width: ${BREAKPOINT}) {
    width: 500px;
  }
`;

const FooterWrapper = styled.div`
  font-size: 10px;
  text-align: center;
  margin: 0 auto;
  width: 30vw;
  @media only screen and (min-width: ${BREAKPOINT}) {
    font-size: 0.8rem;
    width: 180px;
  }
  height: auto;
  a,
  &:visited,
  &:focus,
  &:hover {
    color: ${COLORS.FOOTER};
    text-decoration: none;
  }
`;
