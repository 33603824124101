import React from 'react';
import styled from 'styled-components';

import { getBeaufortByMetersPerSecond, getBeaufortDescription } from '../data/beaufort';
import { getKmPerHourByMetersPerSecond } from '../data/si';
import { getKnotsByMetersPerSecond } from '../data/knots';
import { RiderSkillLevel, RiderWeightClass } from '../data/rider';
import { round } from '../data/utils';
import { getSailSizeByMetersPerSecondAndRiderWeightClass } from '../data/sailSizes';
import { getBoardSizeByRiderWeightClass } from '../data/boardSizes';

import { BREAKPOINT, TAGLINE } from './constants';

interface Props {
  metersPerSecond: number | null;
  windSurferWeightClass: RiderWeightClass;
  windSurferSkillLevel: RiderSkillLevel;
}

export const InfoBlock: React.FC<Props> = ({ metersPerSecond, windSurferWeightClass, windSurferSkillLevel }) => {
  const sailSize = windSurferWeightClass ? getSailSizeByMetersPerSecondAndRiderWeightClass(metersPerSecond ?? 0, windSurferWeightClass) : 0;
  return (
    <Container>
      {!!metersPerSecond ? (
        <>
          <Line>({round(getKnotsByMetersPerSecond(metersPerSecond))} knots)</Line>
          <Line>
            {getBeaufortByMetersPerSecond(metersPerSecond)} Bft - {getBeaufortDescription(getBeaufortByMetersPerSecond(metersPerSecond))}
          </Line>
          <Line>
            {round(metersPerSecond)} m/s | {round(getKmPerHourByMetersPerSecond(metersPerSecond))} km/h
          </Line>
          <Line>
            {!!sailSize ? (
              <span>
                {sailSize} m<sup>2</sup>
              </span>
            ) : (
              <EmptyWithSuper />
            )}
            {!!sailSize && !!windSurferSkillLevel && !!windSurferWeightClass && ` |  `}
            {!!windSurferSkillLevel &&
              !!windSurferWeightClass &&
              `${getBoardSizeByRiderWeightClass(windSurferSkillLevel, windSurferWeightClass)} L`}
          </Line>
        </>
      ) : (
        <>
          <Line>{TAGLINE}</Line>
          <Line>
            <Empty />
          </Line>
          <Line>
            <Empty />
          </Line>
          <Line>
            <EmptyWithSuper />
          </Line>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: #333;
  font-weight: 100;
  text-align: center;
  font-size: 1.5rem;
  min-height: 5.6rem;
  @media only screen and (min-width: ${BREAKPOINT}) {
    font-size: 2rem;
    min-height: 7rem;
  }
`;

const Line = styled.div``;

const EmptyWithSuper: React.FC = () => (
  <span style={{ color: 'transparent' }}>
    m<sup>2</sup>
  </span>
);

const Empty: React.FC = () => <span style={{ color: 'transparent' }}>{` - `}</span>;
