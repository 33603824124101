import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';

export interface Option {
  value: string;
  title: string;
}

interface Props extends StandardTextFieldProps {
  options: Option[];
}

export const Select: React.FC<Props> = ({ options, ...props }) => (
  <TextField select {...props}>
    {options.map(({ value, title }) => (
      <MenuItem key={value} value={value}>
        {title}
      </MenuItem>
    ))}
  </TextField>
);
