/* eslint-disable @typescript-eslint/no-unused-vars */
const black = '#000';
const white = '#fff';
const gray0 = '#eee';
const gray1 = '#ddd';
const gray2 = '#d1d1d1';
/* eslint-enable @typescript-eslint/no-unused-vars */

export const PALETTE = [
  '#9700ff',
  '#6400ff',
  '#3200ff',
  '#0032ff',
  '#0064ff',
  '#0096ff',
  '#00c7ff',
  '#00e6f0',
  '#25c192',
  '#11d411',
  '#00e600',
  '#00fa00',
  '#b8ff61',
  '#fffe00',
  '#ffe100',
  '#ffc800',
  '#ffaf00',
  '#ff9600',
  '#e67d00',
  '#e66400',
  '#dc4a1d',
  '#c8321d',
  '#b4191d',
  '#aa001d',
  '#b40032',
  '#c80064',
  '#fe0096',
];

export const COLORS = {
  TEXT: white,
  LINE: black,
  LABEL: black,
  AXIS: black,
  BACKGROUND: gray0,
  FOOTER: gray2,
  CROSSHAIR: black,
  PALETTE,
};
